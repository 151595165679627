import store from "../store";
import { ref } from "vue";
import date from 'quasar/src/utils/date.js';;

const getStartDateObj = () => {
  const allowToggle = ref(true);

  const selectedProduct =
    store.getters.projectSpecific["selectedProduct"] ?? "";
  const switchOrMove = store.getters.projectSpecific["SwitchOrMove"];
  const endDateContractElec =
    store.getters.projectSpecific["einddatum_contract"] ?? "";
  const endDateContractGas =
    store.getters.projectSpecific["einddatum_contract2"] ?? "";
  const endDateElectricity = date.extractDate(
    store.getters.projectSpecific["einddatumElektra"] ?? "",
    "DD-MM-YYYY"
  );
  const endDateGas = date.extractDate(
    store.getters.projectSpecific["einddatumGas"] ?? "",
    "DD-MM-YYYY"
  );

  const message = ref("");

  const differenceAmount = 6;
  const electricityDifference = date.getDateDiff(
    endDateElectricity,
    Date.now(),
    "months"
  );
  const gasDifference = date.getDateDiff(endDateGas, Date.now(), "months");

  const minStartDate = ref(date.addToDate(Date.now(), { days: 1 }));
  const maxEndDate = ref(date.addToDate(Date.now(), { months: 6 }));
  const start = ref(date.addToDate(Date.now(), { days: 1 }));
  const end = ref(date.addToDate(Date.now(), { months: 6 }));
  const startdate_untill = ref(false);

  if (
    selectedProduct != "" &&
    date.isValid(selectedProduct?.product.startdate_from)
  ) {
    minStartDate.value = date.formatDate(
      selectedProduct.product.startdate_from,
      "YYYY/MM/DD"
    );
  }
  if (
    selectedProduct != "" &&
    date.isValid(selectedProduct?.product.startdate_untill)
  ) {
    startdate_untill.value = true;
    maxEndDate.value = date.formatDate(
      selectedProduct.product.startdate_untill,
      "YYYY/MM/DD"
    );
  } else if (
    selectedProduct != "" &&
    date.isValid(selectedProduct?.product.days_untill)
  ) {
    maxEndDate.value = date.formatDate(
      date.addToDate(Date.now(), {
        days: selectedProduct?.product.days_untill,
      }),
      "YYYY/MM/DD"
    );
  }

  if (switchOrMove == "Move-In") {
    console.log("StartDate: in Move-In");
    start.value = date.getMaxDate(
      date.addToDate(Date.now(), { days: 15 }),
      minStartDate.value
    );
    end.value = date.addToDate(Date.now(), { days: 365 });

    message.value =
      "Inhuizing/Verhuizing. Er moet een handmatige ingangsdatum opgegeven worden. Indien de Prospect eerder aangesloten wenst te worden (tijdens de bedenktijd), kan deze dit kenbaar maken via de telefonische klantenservice van Essent.";
  } else {
    console.log("StartDate: in Switch-In");
    console.log("endDateContractElec" + endDateContractElec);
    console.log(endDateElectricity);
    console.log(electricityDifference);
    console.log(differenceAmount);
    console.log("endDateContractGas" + endDateContractGas);
    console.log(endDateGas);
    console.log(gasDifference);
    console.log(differenceAmount);
    if (!(endDateContractElec == "Bekend" || endDateContractGas == "Bekend")) {
      start.value = date.getMaxDate(
        date.addToDate(Date.now(), { days: 31 }),
        minStartDate.value
      );

      message.value =
        "Einddatum Onbekend/Variabel. De huidige overeenkomst wordt binnen een maand overgenomen door Essent. In samenspraak met de Prospect heb je de mogelijkheid om de ingangsdatum te wijzigen, bijv. vanwege een incentive of bonus die de Prospect mogelijk dient terug te betalen.";
    } else if (
      endDateContractElec == "Bekend" ||
      endDateContractGas == "Bekend"
    ) {
      if (
        electricityDifference >= differenceAmount ||
        gasDifference >= differenceAmount
      ) {
        start.value = date.getMaxDate(
          date.addToDate(Date.now(), { days: 31 }),
          minStartDate.value
        );

        message.value =
          "De huidige overeenkomst wordt binnen een maand overgenomen door Essent. In samenspraak met de Prospect heb je de mogelijkheid om de ingangsdatum te wijzigen, bijv. vanwege een incentive of bonus die de Prospect mogelijk dient terug te betalen.";
      } else {
        start.value = date.getMaxDate(
          date.addToDate(endDateElectricity, { days: 1 }),
          minStartDate.value
        );

        allowToggle.value = false;
        message.value =
          "De huidige overeenkomst loopt binnen 6 maanden af. De overeenkomst wordt aan het einde van de looptijd overgenomen door Essent.";
      }
    }
  }

  const minimalDate = date.formatDate(start.value, "YYYY/MM/DD");
  const maximalDate = date.formatDate(
    date.getMinDate(maxEndDate.value, end.value),
    "YYYY/MM/DD"
  );

  const minDateComparison = new Date(minimalDate);
  const maxDateComparison = new Date(maximalDate);

  if (startdate_untill.value && minDateComparison >= maxDateComparison) {
    message.value =
      message.value +
      "<br /> LET OP, de einddatum van het huidige contract ligt verder dan de uiterste ingangsdatum van dit product. De ingangsdatum is dus aangepast. Dit kan gevolgen hebben voor de prospect zoals een mogelijke opzegboete bij de huidige leverancier.";
  }

  return {
    minimalDate,
    maximalDate,
    allowToggle: allowToggle.value,
    message: message.value,
    startdate_untill: startdate_untill.value,
  };
};

export { getStartDateObj };
