import script from "./ProductSelection.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductSelection.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnToggle,QTabPanels,QTabPanel,QMarkupTable,QImg,QTooltip,QBtn,QSelect});
