<template>
  <q-btn-toggle
    v-model="selectedPanel"
    spread
    no-caps
    toggle-color="primary"
    color="info"
    :options="panels"
    :disable="readonly"
  />
  <q-tab-panels
    v-model="selectedPanel"
    animated
    transition-prev="fade"
    transition-next="fade"
  >
    <q-tab-panel v-for="(product, key) in products" :key="key" :name="key">
      <q-markup-table flat>
        <thead>
          <tr class="text-weight-bold">
            <th class="text-center" style="font-weight: bold !important">
              Aanbieder
            </th>
            <th class="text-center" style="font-weight: bold !important">
              Product
            </th>
            <th class="text-center" style="font-weight: bold !important">
              Details
            </th>
            <th class="text-center" style="font-weight: bold !important"></th>
            <th class="text-center" style="font-weight: bold !important">
              Keuze
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in product"
            :key="key"
            :style="
              choice == key
                ? 'background-color: rgb(240, 255, 240); transition: 0.2s;'
                : 'transition: 0.2s;'
            "
            v-show="showProduct(value)"
          >
            <td class="text-center">
              <q-img
                :src="value.Logo"
                spinner-color="white"
                style="min-width: 100px; max-width: 100px"
              />
            </td>
            <td class="text-center" style="white-space: initial">
              {{ value.selectielijst }}
            </td>
            <td class="text-center">
              <div style="white-space: initial" v-html="value.USP"></div>
              <q-tooltip
                v-if="value.thuisvoordeel"
                style="max-width: 10px"
                anchor="top middle"
                self="bottom middle"
                class="bg-primary text-center text-weight-bold"
                :offset="[-5, -5]"
              >
                De klant wordt automatisch<br />
                aangemeld bij thuisvoordel met<br />
                extra voordelige stroomtarieven
              </q-tooltip>
            </td>
            <td class="text-center">
              <q-btn
                size="12px"
                style="width: 120px"
                color="primary"
                :label="choice == key ? 'Geselecteerd' : 'Kiezen'"
                no-caps
                :disable="choice == key || readonly"
                @click="
                  () => {
                    clearPrevOffer();
                    choice = key;
                    selectRef = '';
                  }
                "
              />
            </td>
            <td class="text-right">
              <q-select
                :readonly="readonly"
                label="Incentive"
                transition-show="jump-down"
                transition-hide="jump-down"
                map-options
                outlined
                dense
                option-label="naam"
                option-value="IncentiveId"
                :options-html="true"
                v-show="choice == key"
                :modelValue="selectRef"
                @update:model-value="
                  select = { incentive: $event, product: value }
                "
                :options="value.incentives"
              />
            </td>
            <td
              style="width: 40px"
              v-show="
                choice == key && installmentAmount && incentiveId == selectRef
              "
            >
              <div class="text-weight-bold">Termijnbedrag:</div>
              <div
                class="text-weight-bolder text-center"
                style="color: #0be3ab"
              >
                € {{ Math.round(roundedInstallmentAmount) }}
              </div>
              <div
                v-show="!vat"
                class="text-weight-bolder text-center"
                style="color: #0be3ab"
              >
                excl. btw
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { date } from "quasar";
import { getStartDateObj } from "../../plugins/start-date.js";

const { formatDate } = date;

//* VueUse
const $store = useStore();

//* Refs
const startDateRef = ref(null);
const selectedPanel = ref("");
const choice = ref(false);
const selectRef = ref("");

//* Computed
const panels = computed(() => $store.getters?.products?.options ?? false);
const view = computed(() => $store.getters.view);
const show_product_base_electra = computed(() =>
  view.value == "retention" ? "verbruik electra" : "EAN Elektra"
);
const show_product_base_gas = computed(() =>
  view.value == "retention" ? "verbruik gas" : "EAN Gas"
);
const products = computed(() => $store.getters.products.products);
const projectSpecific = computed(() => $store.getters.projectSpecific);
const vat = computed(() => projectSpecific.value["_VAT"] ?? false);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const incentiveId = computed(
  () => projectSpecific.value["_Incentive_id"] ?? "Geen"
);
const startDateObj = computed({
  get() {
    return $store.getters.startDateObj ?? {};
  },
  set(newVal) {
    $store.dispatch("setStartDateObj", newVal);
  },
});

const selectedProduct = computed({
  get() {
    return projectSpecific.value["selectedProduct"];
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValues", {
      values: {
        selectedProduct: newVal,
        Termijnbedragaanpassen: "Nee",
        Ingangsdatumaanpassen: "Nee",
      },
    });
    startDateObj.value = getStartDateObj();

    startDateRef.value = formatDate(
      startDateObj.value.minimalDate,
      "DD-MM-YYYY"
    );
    if (startDateObj.value?.startdate_untill) {
      startDateRef.value = formatDate(
        date.getMinDate(
          startDateObj.value.maximalDate,
          startDateObj.value.minimalDate
        ),
        "DD-MM-YYYY"
      );
    }
    setStartDate(startDateRef.value);
  },
});

const installmentAmount = computed(() => {
  if (projectSpecific.value["_ToAmMonth_"]) {
    return projectSpecific.value["_ToAmMonth_"].replace(".", ",");
  }
  return "";
});
const roundedInstallmentAmount = computed(() => {
  if (projectSpecific.value["_ToAmMonth_incl_incentive"]) {
    return projectSpecific.value["_ToAmMonth_incl_incentive"];
  } else if (projectSpecific.value["_ToAmMonth_"]) {
    return projectSpecific.value["_ToAmMonth_"];
  }
  return "";
});
const select = computed({
  get() {
    return selectRef.value;
  },
  set(newVal) {
    selectRef.value = newVal.incentive.IncentiveId;

    let campaignId = newVal.product.campaignidno;
    if (newVal.incentive.IncentiveId != "Geen") {
      campaignId = newVal.product.campaignidyes;
    }
    newVal.product.campaignid = campaignId;

    selectedProduct.value = newVal;

    $store.dispatch("setProjectSpecificValues", {
      values: {
        incentive_titel: newVal.incentive.IncentiveId,
        Incentice_kosten: newVal.incentive.IncentiveKosten,
        Incentive_kosten_Improvers: newVal.incentive.IncentiveKostenImprovers,
      },
    });

    $store.dispatch("send_message", { message: "get_offers" });
    $store.dispatch("send_message", { message: "save_additional_information" });
  },
});

//*  Methods
function setStartDate(startDate) {
  $store.dispatch("setStartDate", startDate);

  $store.dispatch("setProjectSpecificValue", {
    key: "StartDate",
    value: startDate,
  });

  if (typeof projectSpecific.value["_ToAmMonth_"] != "undefined") {
    $store.dispatch("send_message", { message: "get_offers" });
  }
}
function clearPrevOffer() {
  $store.dispatch("clearPrevOffer");
}

function showProduct(value) {
  console.log($store.getters.projectSpecific["verbruik gas"]);
  console.log(projectSpecific.value[show_product_base_gas.value]);

  return (
    (value.contract == "Gas en Elektriciteit" &&
      (!projectSpecific.value["MONO"] ||
        (projectSpecific.value[show_product_base_electra.value] &&
          projectSpecific.value[show_product_base_gas.value]))) ||
    (value.contract == "Elektriciteit" &&
      (projectSpecific.value["MONO"] ||
        (projectSpecific.value[show_product_base_electra.value] &&
          !projectSpecific.value[show_product_base_gas.value]))) ||
    (value.contract == "Gas" &&
      (projectSpecific.value["MONO"] ||
        (!projectSpecific.value[show_product_base_electra.value] &&
          projectSpecific.value[show_product_base_gas.value])))
  );
}

//* Conditions
if (selectedProduct?.value) {
  selectedPanel.value = selectedProduct?.value.product.duur;
  choice.value = selectedProduct?.value.product.productid;
}

if (selectedProduct?.value) {
  selectRef.value = selectedProduct.value.incentive.IncentiveId;
}

if (projectSpecific.value.selectedProduct) {
  $store.dispatch("clearPrevOffer");
  $store.dispatch("send_message", { message: "get_offers" });
  $store.dispatch("send_message", { message: "save_additional_information" });
}
</script>
<style lang="sass"></style>
